import React, {useRef} from 'react';

import Grid from '@mui/material/Grid';

import Page from '../components/Page';
import Section from '../components/Section';
import Typography from '../components/Typography';
import Button from '../components/Button';
import Card from '../components/Card';
import Quote from '../components/Quote';
import FeatureSlider from '../components/FeatureSlider';


import graphicClinicalPros from '../images/graphic_clinicalpros.svg';
import graphicHeart from '../images/heart.svg';
import graphicCalendar from '../images/calendar.svg';
import graphicMedic from '../images/medic.svg';
import graphicAdmin from '../images/administrators.svg';
import graphicPrac from '../images/practitioners.svg';
import graphicPatient from '../images/patient.svg';
import clinicalInstru from '../images/clinical_instruments.png';
import clinicalHealth from '../images/health_card.png';
import clinicalEmergency from '../images/emergency.png';
import clinicalHistory from '../images/history.png';

import microscope from '../images/icon-microscope.svg';
import networkmap from '../images/icon-networkmap.svg';
import user from '../images/icon-user.svg';
import graph from '../images/icon-graph.svg';


const featureSlides = [
  {
    image: clinicalInstru,
    title:'Clinical Instruments',
    content:'Clinically validated tools and instruments housed within the platform allow patients to complete assessments offsite at any time to provide best care and preventative interventions.',
  },
  {
    image: clinicalHealth,
    title:'Health Cards',
    content:'Innowell’s health cards allow real-time, in-depth insights into a patient\'s wellbeing across all domains of mental health, allowing professionals to best prepare for care.',
  },
  {
    image: clinicalEmergency,
    title:'Emergency Contact',
    content:'In-built emergency help features ensure patients have aid at all levels of severity 24/7, with early intervention engaged for patients who require emergency help or are in danger of self-harm.',
  },
  {
    image:clinicalHistory,
    title:'Patient Histories',
    content:'Detailed patient history shared between full circle-of-care enables best mental health support, while the patient is provided full transparent access to their own clinical information and privacy.',
  },
]

const ForClinicians = () => {

  const headerRef = useRef();

  return (
    <Page title='For Clinicians' headerRef={headerRef}>
      {/* Hero */}
      <Section>
        <Grid container direction='row-reverse'>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='Phone showing the Innowell App Dashboard' src={graphicClinicalPros}/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='hero' preset={1}>Person-centred clinical care</Typography>
            <Typography type='paragraph' preset={2}>Built for patients, practitioners, and administrators, Innowell empowers your personalised clinical care with accelerated assessment and triage, real-time risk monitoring, and individual patient care plans. </Typography>
            <Button theme='secondary' onClick={() => headerRef.current.triggerBookNow()}>Book a demo</Button>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Typography type='heading' preset={2}>The BIG problem (that we're working to fix)</Typography>
        <Typography type='paragraph' preset={2}>
          Every year 1-in-3 Australians will need clinical mental health support, and the average wait time to see a qualified mental health professional is over 14 months. The sad news? Only half that number will get it, and when they do it is unlikely that the first specialist, they meet will be the right one for their care needs.
        </Typography>
        <Typography type='paragraph' preset={2}>
          Mental health care is an increasingly critical issue, not just for those who suffer but for the increasingly overloaded administrators and clinicians trying to help, and the current system and tools in-place to properly analyse, triage, and intake those suffering from poor mental health are often not sufficient.
        </Typography>
        <Grid container columnSpacing={2}  sx={{mb: 4}}>
          <Grid item xs={12} sm={4}>
            <Card
              sx={{height: '100%'}}
              theme='mint'>
              <img style={{height: '112px'}} src={graphicHeart} alt='Graphic for stat 1'/>
              <Typography type='heading' preset={4}>50% of Australians will suffer from ill mental health at some stage in their life. </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card
              sx={{height: '100%'}}
              theme='mint'>
              <img style={{height: '112px'}}  src={graphicCalendar} alt='Graphic for stat 2'/>
              <Typography type='heading' preset={4}>20% of Australians, 4.8 million people, will experience a mental or behavioural condition in a 12-month period. </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card
              sx={{height: '100%'}}
              theme='mint'>
              <img style={{height: '112px'}}  src={graphicMedic} alt='Graphic for stat 3'/>
              <Typography type='heading' preset={4}>3.8% of all emergency department presentations in recent studies were mental health related – and increasing year-on-year. </Typography>
            </Card>
          </Grid>
        </Grid>
        <Typography type='heading' preset={2}>Your Innowell Solution</Typography>
        <ul>
          <li>
            <Typography type='paragraph' preset={2}>Facilitate early intervention and reduce risk of hospital readmissions with accelerated risk assessment, triage, and intake. </Typography>
          </li>
          <li>
            <Typography type='paragraph' preset={2}>Optimise care with stepped and staged models endorsed by the Brain & Mind Centre at the University of Sydney. </Typography>
          </li>

          <li>
            <Typography type='paragraph' preset={2}>Increase engagement with care plans between consultations with shared plans of care and clinically validated assessments available remotely.</Typography>
          </li>

          <li>
            <Typography type='paragraph' preset={2}>Manage workflows with patient lists containing result, risks, activity and clinically validated instrument overviews.</Typography>
          </li>
        </ul>
      </Section>

      {/* Administrators/Practitioners/Patients */}
      <Section theme='primary'>
        <Grid container direction='row-reverse'>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='workplaces graphic' src={graphicAdmin}/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='heading' preset={2}>Administrators</Typography>
            <Typography type='paragraph' preset={2}>Innowell’s detailed service analytics and resource management tools provide real-time measurement of risk and outcomes that give you the ability to manage intake and triage (IAR) with a focus on prioritising high-risk and acute cases and connecting them with the clinical care they need, when they need it.</Typography>
          </Grid>
        </Grid>
      </Section>
      <Section theme='primary'>
        <Grid container direction='row-reverse'>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='workplaces graphic' src={graphicPrac}/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='heading' preset={2}>Practitioners</Typography>
            <Typography type='paragraph' preset={2}>Accelerate identification and treatment with access to standardised clinical instruments and individualised patient care plans, and real-time risk monitoring, while Innowell allows you to collaborate with the patient’s full circle-of-care. </Typography>
          </Grid>
        </Grid>

        </Section>
      <Section theme='primary'>
        <Grid container direction='row-reverse'>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='workplaces graphic' src={graphicPatient}/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='heading' preset={2}>Patients</Typography>
            <Typography type='paragraph' preset={2}>Innowell’s digital tools can be used when and where your patients need them, with personalised care that adjusts to their individual needs and gives them ownership over their information and health history.</Typography>
          </Grid>
        </Grid>
      </Section>
      {/* The Clinical Platform */}
      <Section title='The Clinical Platform'>
        <FeatureSlider slides={featureSlides} />
      </Section>

      {/* Quote */}
      <Section theme='mint'>
        <Quote content='Shared information means better communication between myself and my patient&apos;s circle-of-care.'/>
      </Section>

      {/* Icon Grid */}
      <Section>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={graph} alt='Data-driven icon'/>
              <Typography type='heading' preset={3}>Data-driven</Typography>
              <Typography type='paragraph' preset={2}>Real-time, configurable analysis and deep analytics that give you control over meaningful, actionable reporting.</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={user} alt='Person Centric icon'/>
              <Typography type='heading' preset={3}>Person centric</Typography>
              <Typography type='paragraph' preset={2}>Engage patients with remote 24-7 access to clinically validated instruments between consultations. </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={networkmap} alt='Interoperable icon'/>
              <Typography type='heading' preset={3}>Interoperable</Typography>
              <Typography type='paragraph' preset={2}>Integrated with full circle-of-care enhancing shared care plans with other practitioners</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={microscope} alt='Research led icon'/>
              <Typography type='heading' preset={3}>Research led</Typography>
              <Typography type='paragraph' preset={2}>Close collaboration with BMC provides innovative features and ongoing research-based enhancements.</Typography>
            </Card>
          </Grid>
        </Grid>
      </Section>
    </Page>
  )
};

export default ForClinicians;
